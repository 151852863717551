export class Readings {
  static setup() {
    $('.range_field').keyup(function() {
      const data = $(this).data();
      const value = parseFloat($(this).val());
      Readings.clearRangeError($(this));
      if (data.highRangeWarn) {
        const highRange = parseFloat(data.highRangeWarn);
        if (value > highRange) {
          Readings.addRangeError('is higher than the normal high range of ' + highRange, $(this));
        }
      }
      if (data.lowRangeWarn) {
        const lowRange = parseFloat(data.lowRangeWarn);
        if (value < lowRange) {
          return Readings.addRangeError('is lower than the normal low range of ' + lowRange, $(this));
        }
      }
    })

    const dropdownButton = document.getElementById('saveAndAddReadingButton');
    if (dropdownButton) {
      dropdownButton.addEventListener('mousedown', function () {
        setTimeout(function () {
          var currentRecord = document.querySelector('.reading-save-and-add-dropdown [disabled]');
          if (currentRecord) {
            var dropdownMenu = currentRecord.closest('.reading-save-and-add-dropdown');
            dropdownMenu.scrollTop = currentRecord.offsetTop - dropdownMenu.offsetTop - 10;
          }
        }, 100);
      });
    }
  }

  static addRangeError = function(error, input) {
    input.addClass('is-invalid');
    return $("<div class='invalid-feedback'>" + error + "</div>").insertAfter(input);
  }

  static clearRangeError = function(input) {
    $(input).siblings('.invalid-feedback').remove();
    return input.removeClass('is-invalid');
  }


}
